import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { RoutingState } from './services/routing-state.service';
import { HTTPStatus } from './services/interceptor/http.status';
import { HighchartsConfigService } from './services/highcharts-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    HTTPActivity: boolean;

    constructor(
      private readonly translate: TranslateService,
      private readonly languageService: LanguageService,
      private readonly highchartsConfigService: HighchartsConfigService,
      private readonly titleService: Title,
      readonly routingState: RoutingState,
      private readonly httpStatus: HTTPStatus,
    ) {
      this.translate.addLangs(['en', 'nl']);
      this.translate.setDefaultLang('nl');
      const browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang.match(/en|nl/) ? browserLang : 'nl');

      this.routingState.loadRouting();
      this.HTTPActivity = false;
      setTimeout(() => {
        this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
            this.HTTPActivity = status;
        });
      }, 100);
    }

    public ngOnInit(): void {
      this.languageService.setUserLocale();
      this.applyBrand('dwa');
    }

    private applyBrand(style: string): void {
      const dom: any = document.querySelector('body');
      dom.classList.toggle(style);
      this.setTitle("Bright Building");
    }

    private setTitle(newTitle: string): void {
      this.titleService.setTitle(newTitle);
    }
}
