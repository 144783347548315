import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { LocalizedDatePipe } from './localized-date.pipe';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter.pipe';
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [LocalizedDatePipe, CapitalizeFirstLetterPipe],
    providers: [LocalizedDatePipe, CapitalizeFirstLetterPipe],
    exports:   [LocalizedDatePipe, CapitalizeFirstLetterPipe]
})
export class SharedPipesModule {

    static forRoot() {
      return {
          ngModule: SharedPipesModule,
          providers: [],
      };
   }
}
