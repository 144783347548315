import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ModuleConstants } from '../domain/module-constants';
import { environment } from './../../environments/environment';
import { IUser } from './user.service';

export class User {
  constructor(
    public email: string,
    public password: string) { }
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
  readonly OAUTH_URL = '/oauth/token';
  readonly LOGIN_URL = '/login';

  readonly minimumStrengthLevel = 2;

  readonly headers = new HttpHeaders()
              .append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
              .append('authorization', 'Basic '
        + btoa(environment.client_id + ':' + environment.client_secret));

  public constructor(
    private router: Router,
    private httpClient: HttpClient
  ) {}

  public authenticate(credentials: any) {
    this.clearSessionStorage();

    let body = new HttpParams();
    body = body.set('username', credentials.email);
    body = body.set('password', credentials.password);
    body = body.set('grant_type', 'password');
    body = body.set('scope', 'read write');

    return this.httpClient.post(this.OAUTH_URL, body, {headers: this.headers})
       .pipe(map((data: any) => {
          sessionStorage.setItem('access_token', data['access_token']);
          sessionStorage.setItem('token_object', JSON.stringify(data));
        }),
        catchError(err => this.handleError(err))
        );
  }

  public authenticateTwoFactor(credentials: any) {
    this.clearSessionStorage();

    let body = new HttpParams();
    body = body.set('mfa_token', credentials.mfa_token);
    body = body.set('mfa_code', credentials.mfa_code);
    body = body.set('grant_type', 'mfa');
    body = body.set('scope', 'read write');

    return this.httpClient.post(this.OAUTH_URL, body, {headers: this.headers})
       .pipe(map((data: any) => {
          sessionStorage.setItem('access_token', data['access_token']);
          sessionStorage.setItem('token_object', JSON.stringify(data));
        }),
        catchError(err => this.handleError(err))
        );
  }

  public setPassword(userId: number, oldPassword: string, newPassword: string) {
    return this.httpClient.put('api/users/changePassword/' + userId, {
            oldPassword: oldPassword,
            newPassword: newPassword
          }
        ).pipe(catchError(this.handleError));
  }

  // an action by an admin
  public generateResetPasswordToken(userId: number) {
    return this.httpClient.put(
      'api/users/generateResetPasswordToken/' + userId, {}
    ).pipe(catchError(this.handleError));
  }

  // a request by the user
  public requestResetPassword(email: string) {
    return this.httpClient.post(
      'api/login/requestResetPassword', {email: email}
    ).pipe(catchError(this.handleError));
  }

  public resetPassword(token: string, password: string) {
    return this.httpClient.post(
      'api/login/resetPassword/' + token, {oldPassword: '', newPassword: password}
    ).pipe(catchError(this.handleError));
  }

  public getCurrentUser(): Observable<IUser> {
    return this.httpClient.get('/api/users/currentUser')
      .pipe(map((user: any) => {
          this.setUserInSessionStorage(user);
          return user;
        }),
        catchError(err => this.handleError(err))
        );
  }

  updateUser(user: any) {
    if (user.newPassword === null || user.newPassword === undefined || user.newPassword === '' ) {
      delete user.newPassword;
    }
    return this.httpClient.put('/api/users/' + user.id, user).pipe(catchError(this.handleError));
  }

  refreshToken(refresh_token: string) {
    let body = new HttpParams();
    body = body.set('grant_type', 'refresh_token');
    body = body.set('refresh_token', refresh_token);

    return this.httpClient.post(this.OAUTH_URL, body, {headers: this.headers})
      .pipe(map((data: any) => {
          sessionStorage.setItem('access_token', data['access_token']);
          sessionStorage.setItem('token_object', JSON.stringify(data));
        }),
        catchError(err => this.handleError(err))
        );
  }

  public logout(): void {
    this.clearSessionStorage();
    this.router.navigate([this.LOGIN_URL]);
  }

  private clearSessionStorage(): void {
    sessionStorage.removeItem('loginToken');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('siteId');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('endDate');
  }

  public hasPrivilege(privilegeId: number): boolean {
    const user = this.getUserFromSessionStorage();
    return user?.role.privileges.find(p => +p.id === privilegeId) !== undefined;
  }

  public isLoggedIn(): boolean {
    return (sessionStorage.getItem('access_token') !== null && this.getUserFromSessionStorage() !== null);
  }

  public getUserId() {
    const user = this.getUserFromSessionStorage();
    return user.id;
  }

  public setUserInSessionStorage(user) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  public getUserFromSessionStorage() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  private handleError(error: any) {
    return throwError(error);
  }

  public isStrongPassword(passwordStrength: number) {
    return passwordStrength >= this.minimumStrengthLevel;
  }

  get authorisedToViewRuleType() {
    return this.hasPrivilege(ModuleConstants.userPrivileges.viewRuleType);
  }

  get authorisedToEditComponents() {
    return this.hasPrivilege(ModuleConstants.userPrivileges.editComponents);
  }
}
