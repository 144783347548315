export class ModuleConstants {

  // Flips the keys and values of an object ([k, v] => [v, k]).
  static readonly flip = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()));

  public static readonly SUPPORTED_LOCALE = ['en', 'nl'];
  public static readonly server = {
    'url': window.location.protocol + '//' + window.location.hostname,
    'port': window.location.port
  };
  public static readonly emailRegex = "[A-Za-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,3}$";
  public static readonly events = {
    'states': {
      'alarm': 'ALARM',
      'ok': 'OK',
      'open': 'OPEN',
      'undefined': 'UNDEFINED'
    },
    'substates': {
      'unacked': 'UNACKED',
      'acked': 'ACKED'
    }
  };
  public static readonly userPrivileges = {
    'general': 0,
    'addUser': 1,
    'addSite': 2,
    'addGraph': 3,
    'addCustomGraph': 4,
    'exportData': 5,
    'viewProjectContacts': 6,
    'viewDataConnections': 7,
    'viewMetrics': 8,
    'viewProjectUsers': 9,
    'editUserSubscriptions': 10,
    'viewRuleType': 11,
    'viewOverviews': 12,
    'viewUsers': 13,
    'editHandleInsufficientDataQuality': 14,
    'accessTechnicalDocumentation': 15,
    'editEquipmentStructures': 16,
    'viewMeterReadings': 17,
    'viewWkoJaarRapportage': 18,
    'editComponents': 19,
  };
  public static readonly appTypes = {
    'events': 'EVENTS',
    'equipmentstructure': 'EQUIPMENTSTRUCTURE',
    'exportdata': 'EXPORTDATA',
    'dashboard': 'DASHBOARD',
    'help': 'HELP',
    'historian': 'HISTORIAN',
    'samples': 'SAMPLES',
    'analytics': 'ANALYTICS',
    'users': 'USERS',
    'springboard': 'SPRINGBOARD',
    'projects': 'PROJECTS',
    'reports': 'REPORTS',
    'userprofile': 'PROFILES',
    'documents': 'DOCUMENTS',
    'connections': 'CONNECTIONS'
  };
  public static readonly appSubTypes = {
    'view': 'view',
    'detail': 'detail',
    'equipments': 'equipments',
    'landingPage': 'landingPage',
    'config': 'config'
  };
  public static readonly widgetTypes = {
    'table': 'TABLE',
    'historianchart': 'HISTORIANCHART',
    'equipmentchart': 'EQUIPMENTCHART',
    'rulechart': 'RULECHART',
    'custom': 'CUSTOM',
    'gauge': 'GAUGE',
    'dateTimeRange': 'DATE_TIME_RANGE',
    'contactInfo': 'CONTACT_INFO',
    'organisationInfo': 'ORGANISATION_INFO',
    'scheduler': 'SCHEDULER',
    'profile': 'PROFILE'
  };
  public static readonly widgetSubTypes = {
    'actual': 'ACTUAL',
    'archive': 'ARCHIVE',
    'occurrences': 'OCCURRENCES',
    'kpi': 'KPI',
    'user': 'USER',
    'organization': 'ORGANIZATION',
    'site': 'SITE',
    'equipment': 'EQUIPMENT',
    'rule': 'RULE'
  };
  public static readonly entityTypes = {
    'user': 'USER',
    'role': 'ROLE',
    'site': 'SITE',
    'organization': 'ORGANIZATION',
    'userProfile': 'USER_PROFILE',
    'equipment': 'EQUIPMENT',
    'pointType': 'POINT_TYPE'
  };
  public static readonly dataPointTypes = {
    'configPoint': 'CONFIG_POINTS',
    'linkedPoint': 'LINKED_POINTS'
  };
  public static readonly gaugeStatuses = {
    'warning': 'WARNING',
    'ok': 'OK',
    'alarm': 'ALARM',
    'undefined': 'UNDEFINED'
  };
  public static readonly gaugeDataTypes = {
    'rule': 'RULE'
  };
  public static readonly barStatuses = {
    'warning': 'WARNING',
    'ok': 'OK',
    'alarm': 'ALARM',
    'undefined': 'UNDEFINED'
  };
  public static readonly chartTypes = {
    'line': 0,
    'pie': 1,
    'xy': 2,
    'heatmap': 3,
    toString(i: number) {
      return ModuleConstants.flip(ModuleConstants.chartTypes)[i];
    }
  };
  public static readonly chartLines = {
    'line': 1,
    'column': 2,
    'scatter': 3,
    'spline': 4,
    'area': 5,
    'pie': 6,
    'bar': 7,
    toString(i: number) {
      return ModuleConstants.flip(ModuleConstants.chartLines)[i];
    }
  };
  public static readonly chartColors = [
    '#79baa1', '#d4453e', '#002037', '#e9dd6b',
    '#4c6373', '#237a5b', '#00537a', '#90d0e0',
    '#2aa3c8', '#f1925d', '#4f4135', '#ffffff',
    '#FF0000', '#008000', '#4CFF00', '#FF00DC',
  ];
  static readonly AVG_POINT_FUNCTION_ID = 0;
  static readonly MIN_POINT_FUNCTION_ID = 1;
  static readonly MAX_POINT_FUNCTION_ID = 2;
  static readonly SUM_POINT_FUNCTION_ID = 3;
  // This array is purposely not public; it is accessed through the getter functions that follow.
  static readonly pointFunctionList = [
    {id: ModuleConstants.AVG_POINT_FUNCTION_ID, name: 'avg', description: 'Gemiddelde'},
    {id: ModuleConstants.MIN_POINT_FUNCTION_ID, name: 'min', description: 'Minimum'},
    {id: ModuleConstants.MAX_POINT_FUNCTION_ID, name: 'max', description: 'Maximum'},
    {id: ModuleConstants.SUM_POINT_FUNCTION_ID, name: 'sum', description: 'Som'},
  ];
  static get pointFunctions() {
    let result = [];
    ModuleConstants.pointFunctionList.forEach(ob => result.push((({ id, description }) => ({ id, description }))(ob)));
    return result;
  }
  static get subPointFunctions() {
    let result: any = {};
    ModuleConstants.pointFunctionList.forEach(ob => result[ ob.description ] = ob.id.toString());
    return result;
  }
  public static readonly functionsAsList = [
    {id: 0, description: 'Geen'},
    {id: 1, description: 'Gewogen gemiddelde (som 1 * 2) / som 2'},
    {id: 2, description: 'Som (1 + 2)'},
    {id: 3, description: 'Som (1 + 2 + 3)'},
    {id: 4, description: 'Som (1 + 2 + 3 + 4)'},
    {id: 5, description: 'Deling (1 / 2)'},
    {id: 6, description: 'Vermenigvuldiging (1 * 2)'},
    {id: 7, description: 'Min (1 - 2)'},
    {id: 8, description: 'Min (1 - 2 - 3)'},
    {id: 9, description: 'Min (1 - 2 - 3 - 4)'}
  ];
  public static readonly grainTypes = {
    RAW: 99,
    HOUR: 0,
    DAY: 1,
    WEEK: 2,
    MONTH : 3,
    MONTHS2: 4,
    MONTHS3: 5,
    MONTHS6: 6,
    YEAR: 7,
    YEARS5: 8
  };
  public static readonly chartWidgetSubTypes = {
    'normal': 'NORMAL',
    'heatMap': 'HEAT_MAP',
    'benchmark': 'BENCHMARK'
  };
  public static readonly higchartTypes = {
    'heatmap': 'heatmap'
  };
  public static readonly STYLES = [
    { style: 'bright-building', domains: ['portal.bright-building.nl'] }
  ];
  public static readonly RULE_ICONS = {
    ENERGY: 'fa-bolt',
    COMFORT: 'fa-child',
    INSTALLATION: 'fa-cogs',
    PROCESS: 'fa-code-fork',
    DATA: 'fa-database'
  }
  public static readonly BadgeClass = {
    SUCCESS: 'bg-success',
    MINOR_WARNING: 'bg-secondary',
    WARNING: 'bg-warning',
    ERROR: 'bg-danger'
  }
  public static readonly HIGHCHARTS_DWA_COLORS =
  [ '#002037', '#79baa1', '#f1925d', '#e9dd6b', '#237a5b',
  '#00537a', '#90d0e0', '#2aa3c8', '#d4453e', '#4f4135', '#4c6373'
  ];
}
